<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <v-checkbox v-model="isDisabled" class="mt-0" label="Je suis l'auteur" @click.once="fetchContacts" />
    <v-autocomplete
      v-model="selected"
      label="Auteur"
      :disabled="isDisabled"
      :items="items"
      :loading="isLoading"
      item-text="full_name"
      item-value="uid"
      :search-input.sync="search"
      no-filter
      class="ma-0 pa-0"
      @change="onChange"
    />
  </div>
</template>

<script>
  import {debounce} from 'throttle-debounce'

  export default {
    name: 'AuthorSelectField',
    props: {
      defaultOption: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        isDisabled: true,
        isLoading: false,
        search: '',
        items: [],
        selected: null
      }
    },
    watch: {
      search: debounce(500, function (val) {
        this.filter(val)
      })
    },
    created() {
      if (this.defaultOption) {
        this.isDisabled = this.defaultOption.uid === this.$auth.user.sub
        if (!this.isDisabled) {
          this.items.push(this.defaultOption)
          this.selected = this.defaultOption.uid
        }
      }
    },
    methods: {

      async fetchContacts() {

        if (!this.$store.state.contacts.isLoaded && !this.isDisabled) {
          this.isLoading = true
          await this.$store.dispatch('contacts/fetchContacts')
          this.items = this.$store.state.contacts.items
          this.isLoading = false
        }

      },

      entryHasBeenSelected(val) {
        return this.items.find(item => item.full_name === val)
      },

      async filter(val) {
        if (!val || this.entryHasBeenSelected(val)) {
          return
        }

        this.isLoading = true
        let results = await this.$store.dispatch('contacts/searchContacts', val)
        this.items = results.data.list
        this.isLoading = false
      },

      onChange(val) {
        this.$emit('input', val)
      }

    }
  }
</script>
