<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>Création de document</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar title="Création d'un document" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-2 align-self-center">
        <form class="py-3" method="post" @submit.prevent="!file ? submit() : edit()">
          <upload-field v-if="files.length < 1" :have-file="!!files.length" @upload="onFilesInput" />
          <div v-if="files.length" class="mb-5">
            <v-card v-for="(item, index) in files" :key="index" outlined>
              <v-list-item two-line>
                <v-list-item-avatar color="success">
                  <font-awesome-icon class="white--text" size="lg" :icon="['fal', 'file-plus']" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="title">{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.size | prettyBytes }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn x-small depressed fab @click="clearFiles">
                    <font-awesome-icon size="lg" :icon="['fal', 'times']" />
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </div>


          <div v-if="files.length && !file || file">
            <form-category title="Attribution" subtitle="Informations de propriété du document" />


            <v-text-field v-model="model.name" label="Titre" />
            <owner-select-field v-if="!file" v-model="model.patient" />
            <author-select-field v-if="!file" v-model="model.author" />

            <form-category title="Informations" subtitle="Classement, organisation et informations" />

            <file-type-select-field v-model="model.file_type" />
            <v-combobox v-model="model.keywords" multiple chips deletable-chips label="Tags" />
            <v-text-field v-model="model.description" label="Description" />

            <div class="button-form text-center">
              <v-btn class="primary" small type="submit" :loading="loading">Enregistrer</v-btn>
              <template v-if="file">
                <v-btn v-if="!loading" class="mr-2" small>Annuler</v-btn>
                <v-btn class="primary" small type="submit" :disabled="loading" :loading="loading">
                  Enregistrer les
                  modifications
                </v-btn>
              </template>
            </div>
          </div>
          <div v-if="error">
            <span class="error">Fichier manquant</span>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import UploadField from '@/modules/files/components/upload/FileUploadField'
  import OwnerSelectField from '@/modules/files/components/Form/OwnerSelectField'
  import AuthorSelectField from '@/modules/files/components/Form/AuthorSelectField'
  import FileTypeSelectField from '@/modules/files/components/Form/FileTypeSelectField'
  import FileUploadMixin from '@/modules/files/mixins/file-upload'
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'

  export default {
    name: 'FileCreate',

    components: {
      FormCategory,
      UploadField, OwnerSelectField, AuthorSelectField, FileTypeSelectField, ModalsSidebar
    },

    mixins: [FileUploadMixin],

    data() {
      return {
        files: [],
        file: null,
        loading: false,
        error: false
      }
    },

    methods: {
      async submit() {
        this.loading = true
        this.error = !this.files.length

        for (let file of this.files) {
          let item = {file, data: {...this.model, on: this.spaceFileList.uid}}
          this.$store.commit('files/upload/add', item)
        }

        try {
          await this.userFileList.upload(this.spaceFileList.uid)
          this.loading = false
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Fichier ajouté',
            subtitle: 'Le fichier a été ajouté avec succès.'
          })
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Format du fichier invalide',
            subtitle: 'Le format du document n\'est pas valide'
          })
        } finally {
          this.loading = false
        }
        this.$emit('close')
      },

      edit() {
        //TODO edit
      },

      onFilesInput(files) {
        this.files = Array.from(files)
      },

      clearFiles() {
        this.files = []
        this.file = null
      }
    }
  }
</script>
