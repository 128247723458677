<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div
    class="drop-zone mb-4 b-rad-small p-rel d-flex align-center"
    :class="{dragover: dragOverDropZone, empty: !haveFile}"
    @dragenter="dragOverDropZone = true"
    @dragleave="dragOverDropZone = false"
  >
    <div class="content text-center pa-4 w-100 font-weight-light">
      <div class="mx-auto mb-3">
        <font-awesome-icon size="3x" :icon="['fad', 'upload']" class="primary--text" />
      </div>
      Cliquez pour selectionner un fichier sur votre appareil
    </div>

    <input-file class="op-0" @upload="file => {$emit('upload', file)}" />
  </div>
</template>

<script>
  import InputFile from '@/modules/files/components/upload/FileInput'

  export default {
    name: 'FileUploadField',

    components: {InputFile},

    props: {
      'haveFile': {
        type: Boolean
      }
    },

    data() {
      return {
        dragOverDropZone: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .drop-zone {
    border: 2px dashed #bbcfe0;

    &.empty {
      min-height: 60%;
    }

    .content {
      color: #7e9bb5;
    }
  }
</style>
