<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <v-checkbox v-model="isDisabled" class="mt-0" label="Je suis le propriétaire"
                @click.once="fetchPatients"
    />
    <v-autocomplete
      label="Propriétaire"
      :disabled="isDisabled"
      :items="items"
      :loading="isLoading"
      item-text="fullName"
      item-value="uid"
      :search-input.sync="search"
      no-filter
      @change="onChange"
    />
  </div>
</template>

<script>
  import {debounce} from 'throttle-debounce'

  export default {
    name: 'OwnerSelectField',
    data() {
      return {
        isDisabled: true,
        isLoading: false,
        search: '',
        items: []
      }
    },
    watch: {
      search: debounce(500, function (val) {
        this.filter(val)
      })
    },
    methods: {

      async fetchPatients() {
        if (!this.$store.state.patients.isLoaded) {
          this.isLoading = true

          await this.$store.dispatch('patients/fetchPatients', {filters: {mypatients: true}})
          this.items = this.$store.state.patients.items

          this.isLoading = false
        }
      },

      entryHasBeenSelected(val) {
        return this.items.find(item => item.full_name === val || item.fullName === val)
      },

      async filter(val) {
        if (!val || this.entryHasBeenSelected(val)) {
          return
        }

        this.isLoading = true
        this.items = await this.$store.dispatch('patients/searchPatients', {filters: {search_term: val}})
        this.isLoading = false
      },

      onChange(val) {
        this.$emit('input', val)
      }
    }
  }
</script>
